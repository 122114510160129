<template>
  <div id="EditUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
       <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
          <div id="" class="">
             <SideMenu :indexsec="16"/>
          </div>
    
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  data.name,
                  data.lastname,
                  data.email,
                  password_form,
                  role_form,
                  file1,
                  data.phone,
                  passconfirm_form
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR USUARIO</p>
              </div>


              <div class="row mx-0 row-two">
                <div class="EPF2_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                    v-model="data.name"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Apellido</label
                  >
                  <b-form-input
                    v-model="data.lastname"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                    id="inputA"
                    type="text"
                  />
                </div>
                <div class="">
                  <label class="labelE color-1" for="inputE">Email</label>
                  <b-form-input
                    v-model="data.email"
                    required
                    id="inputE"
                    type="email"
                  />
                </div>
              </div>

              <div class="row mx-0 row-one mtrow">
               
                <div class="EPF1_C1">
                  <label class="labelP color-1" for="inputP"
                    >Contraseña</label
                  >
                  <b-form-input
                    v-model="password_form"
                    id="inputP"
                    minlength="8"
                    type="password"
                    :placeholder="'***********'"
                  />
                </div>
                 <div class="">
                  <label class="labelP color-1" for="inputP"
                    >Confirmar contraseña</label
                  >
                  <b-form-input
                    v-model="passconfirm_form"
                    id="inputP"
                    minlength="8"
                    type="password"
                    :placeholder="'***********'"
                  />
                </div>
                
              </div>
            
          <div class="row mx-0 row-two mtrow">
             <div class="EPF2_C1">
                  <label class="labelR color-1" for="inputR">Rol</label>
                  <b-dropdown>
                    <template #button-content>
                       <div class="row"> <span class="drop-text">{{data.role}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeRole('admin')">
                      Admin
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeRole('editor')">
                      Editor - Galería | Tienda
                    </b-dropdown-item>
                     <b-dropdown-item @click="changeRole('comunicacion')">
                      Comunicación - Galería | Noticias
                    </b-dropdown-item>
                     <b-dropdown-item @click="changeRole('multimedia')">
                      Multimedia - Videos
                    </b-dropdown-item>
                    
                  </b-dropdown>
                </div>
                <div class="">
                  <label class="labelTel color-1" for="inputTel">Teléfono</label>
                  <b-form-input
                  
                    v-model="data.phone"
                    required
                     minlength="10"
                    maxlength="10"
                    size="10"
                    pattern="\d*"
                    id="inputTel"
                    type="text"
                  />
                </div>
                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>    
              </div>
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>

              <div class="btn-form">
                <b-btn
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal">El usuario se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
             <div id="edit-user-img" v-if="msg == 'success'">
               <!-- <p class="color-1">Imagen del usuario:</p> -->
           
              <img :src="this.image(data.image)"  alt="imagen de usuario">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';
import { mapActions } from "vuex";
export default {
  name: "EditUser",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      password_form: "",
      passconfirm_form: "",
      role_form: "admin",
       status: "",
      msg: "",
      file1:null,
      msg: "success",
      logo: logo,
      legrafica: legrafica,
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
      IconSuccess: IconSuccess,

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "user",
    };
    this.getInfoById(payload);
    
  },
  computed: {
    data() {
      return this.$store.getters["main/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name, lastname, email, password, rol,image,phone, passconfirm) {
       let id = this.$route.params.id;
      if(password === passconfirm){

        this.status=''
        this.msg=''
             
         
       if(image !=null){
           
          //Creamos el formData
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('image', image);
          //Añadimos el método PUT dentro del formData
          // Como lo hacíamos desde un formulario simple _(no ajax)_
          data.append('_method', 'PUT');
          //Enviamos la petición
         let result = await   this.$store.dispatch("main/editImage", {id:id, data:data});
          console.log(result)
          if(result.data.status=='error'){
              this.status='error'
              this.msg= result.data.message
            }

          if(this.status != 'error'){
            let user = {
            id: id,
            name: name,
            lastname: lastname,
            email: email,
            password: password,
            role: rol,
            phone:phone
          };

          
          let result = await  this.$store.dispatch("main/editItem",  {option:'user', item:user});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
              }else{ // success
                  this.showModal();
              }
          }
           

      }else{
      let user = {
        id: id,
        name: name,
        lastname: lastname,
        email: email,
        password: password,
        role: rol,
        phone:phone
      };

       
      let result = await  this.$store.dispatch("main/editItem",  {option:'user', item:user});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal();
          }
      
      }

       

     
     
     
     
         
      }else{
       
         this.status='error'
         this.msg='Las contraseñas no coinciden.'
      }
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    changeRole: function (value) {
      this.role_form = value;
      this.data.role = value
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
    image: function(img){
        let src = this.url +'/get-user-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'user'})
          if(userimg.data.status=='error'){
              this.msg='error'
          }else{
              this.msg='success'
          }
      }catch(err){
          this.msg='error'
      }
    },
  },
};
</script>


